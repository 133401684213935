html, body {
    overflow-x: hidden;
    height: 100%;
}

body {
    background-color: black;
    position: relative;
    margin: 0px;
}

/* ========== Custom Scrollbar Styling ========== */

::-webkit-scrollbar {
    width: 3px;
}  
::-webkit-scrollbar-track {
background: transparent;
}
::-webkit-scrollbar-thumb {
background: #252525;
}
::-webkit-scrollbar-thumb:hover {
background: rgb(58, 58, 58);
}

/* ========== Element Styling ========== */

/* -._.-._.- Welcome Intro Styling -._.-._.- */
/* 465px width on intro */

.intro {
    position: relative;
    color: white;
    font-family: 'Oswald', sans-serif;
    font-size: 250%;
    margin-top: 125px;
}

.introWelcome {
    z-index: 3;
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0%);
    opacity: 0%;
    animation: WelcomeFadeIn_anim 1s ease-in-out 1s 1 normal forwards,
               WelcomeSlideLeft_anim 0.75s cubic-bezier(.65,-0.03,.41,1.03) 2s 1 normal forwards;
}

.welcomeComma {
    opacity: 0%;
    animation: WelcomeCommaFadeIn_anim 0.75s ease-in 2s 1 normal forwards;
}

.nameVeil {
    z-index: 2;
    background-color: black;
    position: absolute;
    left: calc(50% + 79px);
    transform: translate(-50%, 0%);
    height: 60px;
    width: 291px;
    animation: NameVeilSlideRight_anim 0.8s cubic-bezier(0,-0.01,.72,1) 2.5s 1 normal forwards;
}

.introName {
    z-index: 1;
    background-color: black;
    position: absolute;
    left: calc(50% + 79px);
    transform: translate(-50%, 0%);
}

/* === W.I. Animations === */
@keyframes WelcomeFadeIn_anim {
    from {opacity: 0%;}
    to {opacity: 100%;}
}

@keyframes WelcomeSlideLeft_anim {
    from {left: 50%;}
    to {left: calc(50% - 162px);}
}

@keyframes WelcomeCommaFadeIn_anim {
    from {opacity: 0%;}
    to {opacity: 100%;}
}

@keyframes NameVeilSlideRight_anim {
    from {left: calc(50% + 79px);}
    to {left: calc(50% + 370px);}
}

/* -._.-._.- Content Styling -._.-._.- */

.content {
    position: relative;
    top: 105px;
    height: 1100px;
    opacity: 0%;
    animation: ContentFadeIn_anim 1s ease-in 3.7s 1 normal forwards;
}

.aboutBio {
    text-align: center;
    color: white;
    font-family: 'Montserrat', sans-serif;
    font-size: 115%;
    font-weight: 200;
    line-height: 24pt;
}

.projects {
    position: absolute;
    left: -150%;
    transform: translate(-50%, 0%);
    width: 900px;
    margin-top: 50px;
    text-align: center;
    opacity: 0%;
}
.projectsLtoC {
    animation: ProjectsFadeLtoC_anim 0.5s cubic-bezier(.5,0,.5,1) 0s 1 normal forwards;
}
.projectsCtoL {
    animation: ProjectsFadeCtoL_anim 0.5s cubic-bezier(.7,0,.3,1) 0s 1 normal forwards;
}

.resume {
    position: absolute;
    left: 150%;
    opacity: 0%;
    transform: translate(-50%, 0%);
    margin-top: 50px;
    line-height: 1;
}
.resumeRtoC {
    animation: ResumeFadeRtoC_anim 0.5s cubic-bezier(.5,0,.5,1) 0s 1 normal forwards;
}
.resumeCtoR {
    animation: ResumeFadeCtoR_anim 0.5s cubic-bezier(.7,0,.3,1) 0s 1 normal forwards;
}

.pdfDownload {
    position: absolute;
    opacity: 30%;
    top: 10px;
    right: 10px;
    width: 35px;
    height: 35px;
    transition: 0.3s;
    z-index: 99 !important;
}
.pdfDownload:hover {
    opacity: 80%;
}

/* === C. Animations === */
@keyframes ContentFadeIn_anim {
    from {opacity: 0%;}
    to {opacity: 100%;}
}

@keyframes ProjectsFadeLtoC_anim {
    0% {opacity: 0%; left: -150%;}
    0.01% {opacity: 0%; left: calc(50% - 750px);}
    100% {opacity: 100%; left: 50%;}
}

@keyframes ProjectsFadeCtoL_anim {
    0% {opacity: 100%; left: 50%;}
    99.99% {opacity: 0%; left: calc(50% - 1000px);}
    100% {opacity: 0%; left: -150%;}
}

@keyframes ResumeFadeRtoC_anim {
    0% {opacity: 0%; left: 150%;}
    0.01% {opacity: 0%; left: calc(50% + 750px);}
    100% {opacity: 100%; left: 50%;}
}

@keyframes ResumeFadeCtoR_anim {
    0% {opacity: 100%; left: 50%;}
    99.99% {opacity: 0%; left: calc(50% + 1000px);}
    100% {opacity: 0%; left: 150%;}
}

/* -._.-._.- View Select Buttons -._.-._.- */

.viewButtons {
    position: relative;
    margin-top: 100px;
    height: 50px;
}

.btnStyle {
    background: none;
    border: 1px solid white;
    border-radius: 3px;
    width: 100px;
    height: 50px;
    z-index: 2;
    transform: translate(-50%, 0%);
}

.btnStyle:hover {
    cursor: pointer;
}

.projectsBtn {
    position: absolute;
    left: calc(50% - 100px);
}

.resumeBtn {
    position: absolute;
    left: calc(50% + 100px);
}

.blackText {
    position: relative;
    right: 7px;
    top: 0px;
    color: rgb(75, 75, 75);
    font-family: 'Oswald', sans-serif;
    font-weight: 500;
    font-size: 150%;
    transition: 1.25s;
    width: 100px;
    height: 50px;
    border-radius: 3px;
}

.blackTextText {
    position: absolute;
    left: 50%;
    top: 6px;
    transform: translate(-50%, 0%);
}

.blackTextHoverable {
    transition: 0.5s;
}
.blackTextHoverable:hover {
    color: white;
}

/* -._.-._.- View Indicator State Styles -._.-._.- */

.viewIndicator {
    background-color: white;
    border-radius: 3px;
    width: 100px;
    height: 50px;
    z-index: 1;
    transform: translate(-50%, 0%);
}

.viewIndicatorRtoL {
    position: absolute;
    bottom: 0px;
    animation: viewIndicatorRtoL_anim 0.5s cubic-bezier(.7,0,.3,1) 0s 1 normal forwards;
}

.viewIndicatorLtoR {
    position: absolute;
    animation: viewIndicatorLtoR_anim 0.5s cubic-bezier(.7,0,.3,1) 0s 1 normal forwards;
}

/* === V.I.S. Animations === */
@keyframes viewIndicatorRtoL_anim {
    0% {left: calc(50% + 100px); width: 100px; height: 50px;}
    50% {width: 50px; height: 25px;}
    100% {left: calc(50% - 100px); width: 100px; height: 50px;}
}

@keyframes viewIndicatorLtoR_anim {
    0% {left: calc(50% - 100px); width: 100px; height: 50px;}
    50% {width: 50px; height: 25px;}
    100% {left: calc(50% + 100px); width: 100px; height: 50px;}
}


/* ========== Animation Keyframes ========== */
/* CSS syntax for shorthand 'animation' property:
        animation: name duration timing-function delay iteration-count direction fill-mode play-state;
   Default values:
        animation: none 0 ease 0 1 normal none running
*/