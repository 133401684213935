.projectItem {
    width: 320px;
    display: inline-block;
    margin-left: 30px;
    margin-right: 30px;
    margin-bottom: 30px;
    text-align: left;
}

.previewGif {
    background-color: white;
    width: 320px;
    height: 200px;
    text-align: center;
    border: 1px solid rgb(179, 179, 179);
}

.previewVeil {
    background-color: black;
    width: 320px;
    height: 200px;
}

.previewVeilText {
    position: absolute;
    left: 50%;
    top: calc(50% - 5px);
    transform: translate(-50%, -50%);
    color: white;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 185%;
    letter-spacing: 1px;
    white-space: nowrap;
}

.title {
    color: white;
    margin-top: 5px;
    font-family: 'Oswald', sans-serif;
    font-weight: 300;
    font-size: 125%;
    letter-spacing: 1px;
}

.description {
    color: white;
    margin-top: 5px;
    margin-bottom: 5px;
    font-family: 'Montserrat', sans-serif;
    font-size: 80%;
}

.accessLink {
    color: white;
    font-family: 'Montserrat', sans-serif;
    transition: ease-in-out 0.2s;
    font-size: 90%;
    opacity: 60%;
}

.accessLink:hover {
    transition: ease-in-out 0.2s;
    color: rgb(230, 241, 255);
    opacity: 100%;
}

.aLinkUnclickable {
    pointer-events: none;
    color: rgb(160, 160, 160);
    font-family: 'Montserrat', sans-serif;
    font-size: 80%;
    opacity: 60%;
}